<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Send To Next Process
            </h2>
        </v-card-title>
        <v-divider class="mx-6" />
        <v-card-text>
            <v-form v-model="valid">
                <v-data-table
                    :headers="headers"
                    :items="filteredItems"
                    class="elevation-0 mt-7"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            style="background-color: #eeeeee"
                        >
                            <v-col cols="6" class="d-flex align-center">
                                <h2 class="my-n3 d-flex align-center">
                                    ITEMS
                                </h2></v-col
                            >
                            <v-col cols="6" class="d-flex justify-end"
                                ><v-combobox
                                    v-model="nextProcess"
                                    hide-details
                                    prefix="Destination Process *"
                                    prepend-icon="mdi-tools"
                                    :items="availableProcesses"
                                    item-text="name"
                                    :rules="[rules.required]"
                                    required
                                    class="ma-0 pa-0"
                                    style="width: 100px;"
                                    @change="calculateValues()"
                                />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`header.partNumber`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="partNumberToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.code`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">{{ item.code }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div>
                            <p class="my-0">{{ item.description }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.suggestedProcess`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{
                                    item.nextProcess
                                        ? item.nextProcess.name
                                        : 'There is no next process'
                                }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.available`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{ item.available }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.qty`]="{ item }">
                        <div class="mb-5">
                            <v-text-field
                                v-model="item.qtyToSend"
                                hide-details
                                type="number"
                                :rules="[() => maxValue(item)]"
                            />
                        </div>
                    </template>
                </v-data-table>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                class="mb-3 mr-3"
                :disabled="disableButton() || !valid"
                @click="openConfirmDialog"
            >
                NEXT
            </v-btn>
        </v-card-actions>
        <v-dialog
            :retain-focus="false"
            v-model="confirmDialog"
            persistent
            max-width="700px"
        >
            <v-card>
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="closeConfirmDialog"
                        color="primary"
                        class="ml-n3 mr-2"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h2 class="font-weight-regular">
                        Confirm Send to
                        {{ nextProcess ? nextProcess.name : '' }}
                    </h2>
                </v-card-title>
                <v-divider class="mx-6" />
                <v-card-text>
                    <v-form v-model="valid">
                        <v-data-table
                            :headers="confirmHeaders"
                            :items="workOrder.items.filter(i => i.qtyToSend)"
                            class="elevation-0 mt-7"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            :style="`max-height: ${height}px; overflow-y:auto;`"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                    style="background-color: #eeeeee"
                                >
                                    <v-col
                                        cols="12"
                                        class="d-flex align-center"
                                    >
                                        <h2 class="my-n3 d-flex align-center">
                                            ITEMS
                                        </h2></v-col
                                    >
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.code`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.code }}</p>
                                </div>
                            </template>
                            <template v-slot:[`item.qty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.qtyToSend }}</p>
                                </div>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0 pb-5">
                    <v-row class="ma-2">
                        <v-col cols="9" class="pa-0"
                            ><v-row no-gutters cols="6">
                                <v-col cols="1" class="d-flex align-center">
                                    <v-icon>
                                        mdi-account-arrow-left
                                    </v-icon>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field
                                        v-model="code"
                                        type="password"
                                        hide-details
                                        label="Receiving User Code"
                                        :rules="[rules.required]"
                                        required
                                        class="pa-0 ma-0 pl-2"
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="confirmCode"
                                        type="password"
                                        hide-details
                                        label="Confirm Code"
                                        :rules="[rules.required, matchCode]"
                                        required
                                        class="pa-0 pl-4 ma-0"
                                    />
                                </v-col> </v-row
                        ></v-col>
                        <v-col cols="3" class="d-flex justify-end pa-0 ">
                            <v-btn
                                color="primary"
                                rounded
                                :disabled="!valid"
                                :loading="loading"
                                @click="sendItems"
                            >
                                SEND
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import _ from 'lodash'
import API from '@/services/api'
import cryptoJs from 'crypto-js'

export default {
    name: 'MultipleSend',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        processes: { type: Object, required: true },
    },
    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'NEXT PROCESS',
                value: 'suggestedProcess',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AVAILABLE QTY',
                value: 'available',
                align: 'center',
                sortable: false,
                width: '130',
            },
            {
                text: 'QTY TO SEND',
                value: 'qty',
                align: 'center',
                sortable: false,
                width: '130',
            },
        ],
        confirmHeaders: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUANTITY TO SEND',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
        ],
        rules: {
            required: v => !!v || 'The value is required',
        },

        availableProcesses: [],
        prevProcess: undefined,
        nextProcess: undefined,
        confirmCode: undefined,
        code: undefined,
        confirmDialog: false,
        height: 0,
        partNumberToFilter: undefined,
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
    }),
    computed: {
        filteredItems() {
            //filter available
            let conditions = []
            conditions.push(this.filterAvailable)

            if (this.partNumberToFilter) {
                conditions.push(this.filterForPartNumber)
            }

            //filter by process selected
            if (this.nextProcess) {
                conditions.push(this.filterForNextProcess)
            }

            if (conditions.length > 0) {
                return this.workOrder.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }

            return this.workOrder.items
        },
    },
    mounted() {
        this.workOrder.items.forEach(item => {
            item.processes.forEach(process => {
                const index = this.processes.processes.findIndex(
                    p => p.id == process
                )
                if (index > -1) {
                    const existingProcess = this.availableProcesses.find(
                        ap => ap.id == process
                    )
                    if (!existingProcess) {
                        this.availableProcesses.push(
                            this.processes.processes[index]
                        )
                    }
                }
            })
            if (this.user.process) {
                const filteredProcesses = this.availableProcesses.filter(
                    p => p.id != this.user.process.id
                )
                this.availableProcesses = filteredProcesses
            }
            this.findNextProcess(item)
            this.calculateValues()
            this.onResize()
        })
        if (this.user.permission.includes('markQualityRegisters')) {
            this.availableProcesses.push(this.readyForPackingProcess)
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async sendItems() {
            try {
                this.loading = true
                const encryptedCode = cryptoJs.AES.encrypt(
                    this.code,
                    process.env.VUE_APP_ENCRYPTION_PHRASE
                ).toString()
                this.code = encryptedCode
                this.confirmCode = this.code
                await API.sendBatchToNextProcess({
                    items: this.workOrder.items.filter(i => i.qtyToSend),
                    workOrderId: this.workOrder.id,
                    prevProcess: this.user.process,
                    nextProcess: this.nextProcess,
                    receivingSignCode: this.code,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        calculateValues() {
            try {
                this.workOrder.items.forEach(item => {
                    let processes = item.processes.map(processId => {
                        return item.dataProcesses.find(p => p.id == processId)
                    })

                    let availableProcesses = _.cloneDeep(processes)
                    const processIndex = processes.findIndex(
                        p => p.id == this.user.process.id
                    )
                    let prevProcess
                    if (processIndex > -1) {
                        prevProcess = processes[processIndex]
                        availableProcesses.splice(processIndex, 1)
                    }

                    availableProcesses.push(this.readyForPackingProcess)

                    if (this.nextProcess.id == this.readyForPackingProcess.id) {
                        let totalDelivered = 0
                        let totalReleased = 0
                        if (item.quality) {
                            totalReleased = item.quality.reduce(
                                (total, register) =>
                                    total + (Number(register.releaseQty) || 0),
                                0
                            )
                        }
                        if (item.deliveries) {
                            let delivered = item.deliveries.filter(
                                register =>
                                    register.prevProcess.id == prevProcess.id
                            )
                            totalDelivered = delivered.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }
                        item.available = totalReleased - totalDelivered
                    } else {
                        let totalProduced = 0
                        let totalDelivered = 0
                        if (item.production) {
                            let produced = item.production.filter(
                                register =>
                                    register.process.id == prevProcess.id
                            )
                            totalProduced = produced.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }
                        if (item.deliveries) {
                            let delivered = item.deliveries.filter(
                                register =>
                                    register.prevProcess.id == prevProcess.id
                            )
                            totalDelivered = delivered.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }

                        item.available = totalProduced - totalDelivered
                    }
                    item.qtyToSend = Number(item.available)
                })
            } catch (error) {
                console.error(error)
            }
        },

        findNextProcess(item) {
            try {
                let processes = item.processes.map(processId => {
                    return item.dataProcesses.find(p => p.id == processId)
                })

                const processIndex = processes.findIndex(
                    p => p.id == this.user.process.id
                )
                let nextProcess
                if (processIndex > -1) {
                    if (processIndex == processes.length - 1) {
                        nextProcess = this.readyForPackingProcess
                    } else {
                        nextProcess = processes[processIndex + 1]
                    }
                }

                item.nextProcess = nextProcess
            } catch (error) {
                console.error(error)
            }
        },

        matchCode() {
            return !!(
                this.code &&
                this.confirmCode &&
                this.code === this.confirmCode
            )
        },

        openConfirmDialog() {
            this.confirmDialog = true
        },

        closeConfirmDialog() {
            this.confirmDialog = false
        },

        close() {
            this.workOrder.items.forEach(item => {
                delete item.qtyToSend
            })
            this.$emit('closeDialog')
        },

        disableButton() {
            return !this.workOrder.items.find(item => item.qtyToSend > 0)
        },

        onResize() {
            this.height = window.innerHeight - 260
        },

        filterAvailable(item) {
            return item.available && item.available > 0
        },

        filterForPartNumber(item) {
            return (
                item.partNumber &&
                item.partNumber
                    .toLowerCase()
                    .includes(this.partNumberToFilter.toLowerCase())
            )
        },

        filterForNextProcess(item) {
            if (
                item.nextProcess &&
                item.nextProcess.id.toLowerCase() ==
                    this.nextProcess.id.toLowerCase()
            ) {
                return true
            } else {
                delete item.qtyToSend
                return false
            }
        },
        maxValue(item) {
            return item.qtyToSend > item.available || item.qtyToSend <= 0
                ? 'The quantity to be sent cannot be greater than the quantity available'
                : true
        },
    },
}
</script>

<style></style>
